import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import SignIn from './components/SignIn';
import Home from './components/Home';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return sessionStorage.getItem('isAuthenticated') === 'true';
  });

  const [username, setUsername] = useState(() => {
    return sessionStorage.getItem('username') || '';
  });

  const handleSignIn = (user) => {
    setIsAuthenticated(true);
    setUsername(user);
    sessionStorage.setItem('isAuthenticated', 'true');
    sessionStorage.setItem('username', user); 
  };

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('isAuthenticated');
      sessionStorage.removeItem('username');
    };
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<SignIn onSignIn={handleSignIn} />} />
        <Route path="/home" element={isAuthenticated ? <HomeWithHeaderFooter username={username} /> : <Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

const HomeWithHeaderFooter = ({ username }) => (
  <>
    <Header username={username} />
    <Home />
  </>
);


export default App;
