import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from './Modal'; // Adjust the import path if necessary
import './style.css';
import logo from '../img/logo.png';

const SignIn = ({ onSignIn }) => {
    const [username, setUsername] = useState('');
    const [pin, setPin] = useState('');
    const [error, setError] = useState('');
    const [showForgotPinModal, setShowForgotPinModal] = useState(false);
    const [staffName, setStaffName] = useState('');
    const [role, setRole] = useState('');
    const [newPin, setNewPin] = useState('');
    const [confirmNewPin, setConfirmNewPin] = useState('');
    const [forgotPinError, setForgotPinError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            const response = await fetch('/php/signin.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, pin }),
            });

            const result = await response.json();

            if (result.status === 'success') {
                onSignIn(username);
                navigate('/home');
            } else {
                setError(result.error || 'Invalid username or PIN. Please try again.');
                setTimeout(() => setError(''), 3000);
            }
        } catch (error) {
            console.error('Error during sign-in:', error);
            setError('An error occurred. Please try again.');
            setTimeout(() => setError(''), 3000);
        }
    };

    const handleForgotPinSubmit = async (e) => {
        e.preventDefault();
        setForgotPinError('');
    
        if (newPin !== confirmNewPin) {
            setForgotPinError('PINs do not match');
            return;
        }

    
        try {
            const response = await fetch('/php/resetpin.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ staffName, role, newPin }),
            });
    
            const result = await response.json();
    
            if (result.status === 'success') {
                setShowForgotPinModal(false);
                setNewPin('');
                setConfirmNewPin('');
                setStaffName('');
                setRole('');
                
            } else {
                setForgotPinError(result.error || 'Failed to reset PIN. Please try again.');
                setTimeout(() => setForgotPinError(''), 3000);
            }
        } catch (error) {
            console.error('Error during PIN reset:', error);
            setForgotPinError('An error occurred. Please try again.');
            setTimeout(() => setForgotPinError(''), 3000);
        }
    };
    

    // const resetForgotPinForm = () => {
    //     setStaffName('');
    //     setRole('');
    //     setNewPin('');
    //     setConfirmNewPin('');
    //     setForgotPinError('');
    // };

    return (
        <div>
            <div className="hero">
                <div className="heading">
                    <img src={logo} alt="logo" />
                    <h2>ADMIN PANEL</h2>
                </div>

                <div className="form section-animated" id="message-form">
                    <div className="contactus-form">
                        <form method="post" action="#" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <input
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    placeholder="Enter Username"
                                    required
                                />
                                <label htmlFor="username">Username</label>
                            </div>
                            <div className="form-group">
                                <input
                                    type="password"
                                    value={pin}
                                    onChange={(e) => setPin(e.target.value)}
                                    placeholder="Enter PIN"
                                    required
                                    minLength={4}
                                    maxLength={4}
                                />
                                <label htmlFor="pin">PIN</label>
                            </div>
                            {error && <p className="error-message">{error}</p>}
                            <button
                                
                                className="btnn-link"
                                onClick={() => setShowForgotPinModal(true)}
                            >
                                Forgot PIN?
                            </button>
                            <button type="submit" className="btnn">Sign In</button>
                            
                        </form>
                    </div>
                </div>
            </div>

            {/* Forgot PIN Modal using Custom Modal Component */}
            {showForgotPinModal && (
                <Modal onClose={() => setShowForgotPinModal(false)} title="Reset Your PIN"
                onSubmit={handleForgotPinSubmit}
                submitButtonText="Reset"
                showCancel={true}
                showSubmitButton={true}
                className="reset-modal">
                    <div className='reset-form'>
                        <div className="form-group">
                            <input
                                type="text"
                                value={staffName}
                                onChange={(e) => setStaffName(e.target.value)}
                                placeholder="Enter Staff Name"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                                placeholder="Enter Role"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                value={newPin}
                                onChange={(e) => setNewPin(e.target.value)}
                                placeholder="Enter New PIN"
                                required
                                minLength={4}
                                maxLength={4}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                value={confirmNewPin}
                                onChange={(e) => setConfirmNewPin(e.target.value)}
                                placeholder="Confirm New PIN"
                                required
                                minLength={4}
                                maxLength={4}
                            />
                        </div>
                        {forgotPinError && <p className="error-message">{forgotPinError}</p>}
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default SignIn;
