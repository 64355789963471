
import React from 'react';

const Modal = ({ title, onClose, onSubmit, submitButtonText, showCancel, showSubmitButton, children, className }) => {
    return (
        <div className="modal">
            <div className={`modal-content ${className}`}>
                    <h2 className='modal-heading'>{title}</h2>
                    <i className='fas fa-times-circle' onClick={onClose}></i>
                <form onSubmit={onSubmit}>
                    {children}
                    <div className="modal-buttons">
                        {showCancel && (
                            <button type="button" className="profiles-btn cancel-btn" onClick={onClose}>
                                Cancel
                            </button>
                        )}
                        {showSubmitButton && (
                            <button type="submit" className="profiles-btn add-btn">
                                {submitButtonText}
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Modal;
