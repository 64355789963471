import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from './Modal';
import './profiles.css';
import './home-style.css';
import placeholderImage from '../img/placeholder-img.png';


const Soldout = () => {
    const [itemToInstock, setItemToInstock] = useState(null);
    const [showInstockModal, setShowInstockModal] = useState(false);
    const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');
    const [menuItems, setMenuItems] = useState([]);
    const itemsPerPage = 50;
    const totalPages = Math.ceil(menuItems.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = menuItems.slice(indexOfFirstItem, indexOfLastItem);

    const fetchMenuItems = async () => {
        setIsSubmitting(true);
        try {
            const response = await fetch("/php/fetch-soldout-items.php");
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const responseData = await response.json();
            if (responseData.status !== 'success' || !Array.isArray(responseData.data)) {
                throw new Error('Invalid response data');
            }

            setMenuItems(responseData.data);

        } catch (error) {
            console.error("Error fetching menu items:", error.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        fetchMenuItems();
    }, []);

    const handleCloseModal = () => {
        setShowInstockModal(false);
        setShowDeleteItemModal(false);
    };

    const handleMarkAsInstock = (item) => {
        setItemToInstock(item);
        setShowInstockModal(true);
    };

    const confirmMarkAsInstock = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        if (!itemToInstock || !itemToInstock.id) {
            setErrorWithTimeout('Invalid item selected for marking as available');
            setIsSubmitting(false);
            return;
        }

        try {
            const response = await fetch(`/php/soldout-items.php?id=${itemToInstock.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...itemToInstock,
                    in_stock: 1
                }),
            });

            const data = await response.json();
            if (data.status === 'success') {
                showSuccess('Item marked as available');
            } else {
                setErrorWithTimeout('Error marking item as available');
            }
        } catch (error) {
            console.error('Error during PUT request:', error.message);
            setErrorWithTimeout('Error marking item as available');
        } finally {
            setTimeout(() => {
                setIsSubmitting(false);
                setShowInstockModal(false);
                fetchMenuItems();
            }, 500);
        }
    };

    const handleDeleteItem = (item) => {
        setItemToDelete(item);
        setShowDeleteItemModal(true);
    };

    const confirmDeleteItem = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        if (!itemToDelete || !itemToDelete.id) {
            setErrorWithTimeout('Invalid item selected for deletion');
            setIsSubmitting(false);
            return;
        }

        try {
            const response = await axios.delete('/php/add-new-item.php', {
                data: {
                    id: itemToDelete.id
                }
            });

            if (response.data.status === 'success') {
                showSuccess('Item deleted successfully');
            } else {
                setErrorWithTimeout('Error deleting item');
            }
        } catch (error) {
            setErrorWithTimeout('Error deleting item');
            console.error('Error deleting item:', error);
        } finally {
            setTimeout(() => {
                setIsSubmitting(false);
                handleCloseModal();
                fetchMenuItems();
            }, 500);
        }
    };


    const showSuccess = (message) => {
        setSuccessMessage(message);
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 3000);
    };

    const setErrorWithTimeout = (errorMessage) => {
        setError(errorMessage);
        if (errorMessage) {
            setTimeout(() => {
                setError('');
            }, 3000);
        }
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div>
            {showSuccessMessage && <div className="success-message">{successMessage}</div>}
            <div className="items-list">

                {currentItems.length === 0 ? (
                    <div className="no-users-found">No items found.</div>
                ) : (
                    currentItems.map((item, index) => (
                        <div key={item.id} className="kid">
                            <div className='item-header'>
                                <span className="item-number">{(currentPage - 1) * itemsPerPage + index + 1}</span>
                                {item.image ? (
                                    <img
                                        src={`https://lkofoods.com/uploads/${item.image}`}
                                        alt="Item"
                                        className="item-photo"
                                    />
                                ) : (
                                    <img
                                        src={placeholderImage}
                                        alt="Placeholder"
                                        className="item-photo"
                                    />
                                )}
                                <span className='item-name'>{item.item_name}</span>
                                {item.price !== "0.00" && <span className="item-price">£{item.price}</span>}
                            </div>

                            <div className='action-buttons'>
                                <button className="restock-btn" onClick={() => handleMarkAsInstock(item)}>
                                    <i class="fas fa-check-double"></i>
                                </button>
                                <button className="delete-btn" onClick={() => handleDeleteItem(item)}>
                                    <i className="fas fa-trash-alt"></i>
                                </button>

                            </div>
                        </div>
                    ))
                )}
            </div>



            {/* Pagination Controls */}
            <div className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index + 1}
                        onClick={() => handlePageChange(index + 1)}
                        className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                    >
                        {index + 1}
                    </button>
                ))}

                {error && (
                    <div className="error-overlay">
                        <div className='error-overlay-container'>
                            <i className="fas fa-exclamation-triangle"></i>
                            <p className="error-overlay-message">{error}</p>
                        </div>
                    </div>
                )}


                {isSubmitting && (
                    <div className="spinner-overlay">
                        <div className="spinner"></div>
                    </div>
                )}

                {showDeleteItemModal && (
                    <Modal
                        title="Delete Item"
                        onClose={() => setShowDeleteItemModal(false)}
                        onSubmit={confirmDeleteItem}
                        submitButtonText="Delete"
                        showCancel={true}
                        showSubmitButton={true}
                        className="modal-content delete-modal-content"
                    >
                        <div>
                            <p className='delete-modal-heading'>Are you sure you want to delete this item?</p>
                        </div>
                    </Modal>
                )}

                {
                    showInstockModal && (
                        <Modal
                            title="Mark Item as Available"
                            onClose={handleCloseModal}
                            onSubmit={confirmMarkAsInstock}
                            submitButtonText="Mark as Available"
                            showCancel={true}
                            showSubmitButton={true}
                            className="delete-modal-content"
                        >
                            <div className="delete-modal-heading">
                                <p>Are you sure you want to mark this item as available?</p>
                            </div>
                        </Modal>
                    )
                }

            </div>
        </div>
    );
};
export default Soldout;