import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from './Modal';
import placeholderImage from '../img/placeholder-img.png';


const ItemsTab = () => {
    const [error, setError] = useState(null);
    const [showAddItemModal, setShowAddItemModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showSoldoutModal, setShowSoldoutModal] = useState(false);


    const [itemToDelete, setItemToDelete] = useState(null);
    const [itemToSoldout, setItemToSoldout] = useState(null);

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [photo, setPhoto] = useState(null);
    const [photoSrc, setPhotoSrc] = useState(placeholderImage);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedRequiredOptions, setSelectedRequiredOptions] = useState([]);
    const [newRequiredOptions, setNewRequiredOptions] = useState([]);
    const [newOptionalOptions, setNewOptionalOptions] = useState([]);
    const [selectedOptionalOptions, setSelectedOptionalOptions] = useState([]);
    const [menuItems, setMenuItems] = useState([]);
    const [requiredOptions, setRequiredOptions] = useState([]);
    const [optionalOptions, setOptionalOptions] = useState([]);
    const [itemName, setItemName] = useState('');
    const [itemDescription, setItemDescription] = useState('');
    const [price, setPrice] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [categories, setCategories] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState("all");
    const itemsPerPage = 50;
    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
    }, [filteredItems]);


    const fetchMenuItems = async () => {
        setIsSubmitting(true);
        try {
            const response = await fetch("/php/fetch-instock-items.php");

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();

            if (responseData.status !== 'success' || !Array.isArray(responseData.data)) {
                throw new Error('Invalid response data');
            }

            setMenuItems(responseData.data);
            setFilteredItems(responseData.data);

        } catch (error) {
            console.error("Error fetching menu items:", error.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    const fetchCategories = async () => {
        setIsSubmitting(true);
        try {
            const response = await fetch('/php/categories.php');
            const data = await response.json();
            setTimeout(() => {
                setCategories(data);
                setIsSubmitting(false);
            }, 1000);
        } catch (error) {
            console.error('Error fetching categories:', error);
            setIsSubmitting(false);
        }
    };

    const fetchOptions = async () => {
        try {
            const response = await axios.get('/php/fetch-options.php');
            setRequiredOptions(response.data.required_options);
            setOptionalOptions(response.data.optional_options);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    useEffect(() => {
        fetchMenuItems();
        fetchCategories();
        fetchOptions();
    }, []);

    const setErrorWithTimeout = (errorMessage) => {
        setError(errorMessage);
        if (errorMessage) {
            setTimeout(() => {
                setError('');
            }, 3000);
        }
    };

    const confirmMarkAsSoldout = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        if (!itemToSoldout || !itemToSoldout.id) {
            setErrorWithTimeout('Invalid item selected for marking as sold out');
            setIsSubmitting(false);
            return;
        }

        try {
            const response = await fetch(`/php/soldout-items.php?id=${itemToSoldout.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ in_stock: 0 })
            });

            const data = await response.json();
            if (data.status === 'success') {
                showSuccess('Item marked as sold out');
            } else {
                setErrorWithTimeout('Error marking item as sold out');
            }
        } catch (error) {
            setErrorWithTimeout('Error marking item as sold out');
        } finally {
            setTimeout(() => {
                setIsSubmitting(false);
                setShowSoldoutModal(false);
                fetchMenuItems();
            }, 500);
        }
    };

    const handleFilterChange = (selectedCategory) => {
        setSelectedFilter(selectedCategory);
        if (selectedCategory === "all") {
            setFilteredItems(menuItems);
        } else {
            const selectedCategoryId = parseInt(selectedCategory);
            const filtered = menuItems.filter(item => item.category_id === selectedCategoryId);
            setFilteredItems(filtered);
        }
    };

    useEffect(() => {
    }, [selectedFilter]);

    const handleAddItemClick = () => {
        setShowAddItemModal(true);
        resetFormState();
        fetchOptions();
    };

    const handleCloseModal = () => {
        setShowAddItemModal(false);
        setShowDeleteModal(false);
        setShowSoldoutModal(false);
        resetFormState();
    };

    const resetFormState = () => {
        setItemName('');
        setItemDescription('');
        setPrice('');
        setCategoryId('');
        setSelectedRequiredOptions([]);
        setNewRequiredOptions([]);
        setSelectedOptionalOptions([]);
        setNewOptionalOptions([]);
        setRequiredOptions([]);
        setOptionalOptions([]);
        setPhoto(null);
        setPhotoSrc(placeholderImage);
    };


    const handleAddSubmit = async (e) => {
        e.preventDefault();

        // Validate required fields for menu item
        if (!itemName || !categoryId) {
            setErrorWithTimeout('Please fill in all required fields.');
            return;
        }

        setIsSubmitting(true);


        // Create a FormData object to handle both text data and the file
        const formData = new FormData();
        formData.append('_method', 'POST'); // Simulate POST request
        formData.append('item_name', itemName);
        formData.append('item_description', itemDescription);
        formData.append('price', price || 0.00);
        formData.append('category_id', categoryId);

        // Append selected required options (if any)
        formData.append('selected_required_options', JSON.stringify(selectedRequiredOptions || []));

        // Append selected optional options (if any)
        formData.append('selected_optional_options', JSON.stringify(selectedOptionalOptions || []));

        // Append new required options (if any)
        formData.append('new_required_options', JSON.stringify(newRequiredOptions || []));

        // Append new optional options (if any)
        formData.append('new_optional_options', JSON.stringify(newOptionalOptions || []));

        // Append the photo file if it's uploaded
        if (photo) {
            formData.append('photo', photo);
        }

        try {
            await axios.post('/php/add-new-item.php', formData, {
                headers: {
                    // 'Content-Type': 'multipart/form-data', // Axios will set this header automatically when using FormData
                },
            });

            setTimeout(() => {
                setIsSubmitting(false);
                handleCloseModal();
                fetchMenuItems();
                showSuccess('Menu item added successfully');
            }, 500);
        } catch (error) {
            setErrorWithTimeout('Error adding menu item.');
            setIsSubmitting(false);
            console.error('Error adding menu item:', error);
        }
    };

    const handleDeleteItem = (item) => {
        setItemToDelete(item);
        setShowDeleteModal(true);
    };

    const handleMarkAsSoldout = (item) => {
        setItemToSoldout(item);
        setShowSoldoutModal(true);
    };



    const confirmDeleteItem = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        if (!itemToDelete || !itemToDelete.id) {
            setErrorWithTimeout('Invalid item selected for deletion');
            setIsSubmitting(false);
            return;
        }

        try {
            const response = await axios.delete('/php/add-new-item.php', {
                data: {
                    id: itemToDelete.id
                }
            });

            if (response.data.status === 'success') {
                showSuccess('Item deleted successfully');
            } else {
                setErrorWithTimeout('Error deleting item');
            }
        } catch (error) {
            setErrorWithTimeout('Error deleting item');
            console.error('Error deleting item:', error);
        } finally {
            setTimeout(() => {
                setIsSubmitting(false);
                handleCloseModal();
                fetchMenuItems();
            }, 500);
        }
    };


    const showSuccess = (message) => {
        setSuccessMessage(message);
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 3000);
    };

    // const capitalizeFirstLetter = (string) => {
    //     if (typeof string !== 'string' || string.length === 0) return '';
    //     return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    // };



    // Handle photo selection with size check
    const handlePhotoChange = (e) => {
        const file = e.target.files[0];
        const maxSize = 5 * 1024 * 1024; // 5MB limit

        if (file) {
            // Check file size
            if (file.size > maxSize) {
                alert('File size exceeds the 5MB limit.');
                e.target.value = ''; // Reset file input
                return;
            }

            // Revoke previous photo URL if it exists and isn't the placeholder
            if (photoSrc && photoSrc !== placeholderImage) {
                URL.revokeObjectURL(photoSrc);
            }

            // Set the new photo source and file
            const newPhotoSrc = URL.createObjectURL(file);
            setPhotoSrc(newPhotoSrc);
            setPhoto(file);
        }
    };


    const handleRemovePhoto = () => {
        // Revoke the object URL if there is an uploaded image
        if (photoSrc && photoSrc !== placeholderImage) {
            URL.revokeObjectURL(photoSrc);
        }

        // Reset to placeholder image and clear the file input
        setPhoto(null);
        setPhotoSrc(placeholderImage);
        document.getElementById('fileInput').value = ''; // Clear file input field
    };


    // Handle selection of a required option
    const handleRequiredOptionSelect = (optionId) => {
        if (optionId) {
            const selectedOption = requiredOptions.find(option => option.id === optionId);
            if (selectedOption && !selectedRequiredOptions.some(option => option.id === selectedOption.id)) {
                setSelectedRequiredOptions(prevState => [
                    ...prevState,
                    selectedOption
                ]);
            }
        }
    };

    // Handle selection of an optional option
    const handleOptionalOptionSelect = (optionId) => {
        if (optionId) {
            const selectedOption = optionalOptions.find(option => option.id === optionId);
            if (selectedOption && !selectedOptionalOptions.some(option => option.id === selectedOption.id)) {
                setSelectedOptionalOptions(prevState => [
                    ...prevState,
                    selectedOption
                ]);
            }
        }
    };
    // Handle input changes for the new required option fields
    const handleNewOptionNameChange = (e, index) => {
        const updatedOptions = [...newRequiredOptions];
        updatedOptions[index].option_name = e.target.value;
        setNewRequiredOptions(updatedOptions);
    };

    const handleNewOptionPriceChange = (e, index) => {
        const updatedOptions = [...newRequiredOptions];
        updatedOptions[index].option_price = e.target.value;
        setNewRequiredOptions(updatedOptions);
    };
    // Handle removing a required option
    const handleRemoveRequiredOption = (index) => {
        const updatedOptions = selectedRequiredOptions.filter((_, i) => i !== index);
        setSelectedRequiredOptions(updatedOptions);
    };


    // Function to remove a newly added required option
    const handleRemoveNewRequiredOption = (index) => {
        const updatedOptions = newRequiredOptions.filter((_, i) => i !== index);
        setNewRequiredOptions(updatedOptions);
    };

    // Function to display a new required option input field when "Add Option" is clicked
    const handleAddNewRequiredOption = (e) => {
        e.preventDefault();
        setNewRequiredOptions(prevState => [
            ...prevState,
            { option_name: '', option_price: '', isNew: true, option_type: 'required' } // New entry with empty name, price, and type
        ]);
    };

    // Handle removing an optional option
    const handleRemoveOptionalOption = (index) => {
        const updatedOptions = selectedOptionalOptions.filter((_, i) => i !== index);
        setSelectedOptionalOptions(updatedOptions);
    };

    // Add a new input field for entering a new optional option
    const handleAddNewOptionalOption = (e) => {
        e.preventDefault();
        setNewOptionalOptions(prevState => [
            ...prevState,
            { option_name: '', option_price: '', isNew: true, option_type: 'optional' } // New entry with empty name, price, and type
        ]);
    };

    // Handle input changes for the new optional option fields
    const handleNewOptionalOptionNameChange = (e, index) => {
        const updatedOptions = [...newOptionalOptions];
        updatedOptions[index].option_name = e.target.value;
        setNewOptionalOptions(updatedOptions);
    };

    const handleNewOptionalOptionPriceChange = (e, index) => {
        const updatedOptions = [...newOptionalOptions];
        updatedOptions[index].option_price = e.target.value;
        setNewOptionalOptions(updatedOptions);
    };

    // Remove a new optional option input field
    const handleRemoveNewOptionalOption = (index) => {
        const updatedOptions = newOptionalOptions.filter((_, i) => i !== index);
        setNewOptionalOptions(updatedOptions);
    };



    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className="items-tab-content">
            {isSubmitting && (
                <div className="spinner-overlay">
                    <div className="spinner"></div>
                </div>
            )}
            {showSuccessMessage && <div className="success-message">{successMessage}</div>}
            <div className="filter-dropdown">
                <select
                    id="kid-status-filter"
                    className="filter-btn"
                    value={selectedFilter}
                    onChange={(e) => handleFilterChange(e.target.value)}
                >
                    <option value="all">All Items</option>
                    {categories.map(category => (
                        <option key={category.id} value={category.id}>
                            {category.category_name}
                        </option>
                    ))}
                </select>
            </div>

            <div className="top-right top-right-user">
                <button className="profiles-btn add-btn" onClick={handleAddItemClick}>
                    Add Item
                </button>
            </div>
            <div className="items-list">
                {currentItems.length === 0 ? (
                    <div className="no-users-found">No items found</div>
                ) : (
                    currentItems.map((item, index) => (
                        <div key={item.id} className="kid">
                            <div className="item-header">
                                <span className="item-number">{(currentPage - 1) * itemsPerPage + index + 1}</span>
                                {item.image ? (
                                    <img
                                        src={`https://lkofoods.com/uploads/${item.image}`}
                                        alt="Item"
                                        className="item-photo"
                                    />
                                ) : (
                                    <img
                                        src={placeholderImage}
                                        alt="Placeholder"
                                        className="item-photo"
                                    />
                                )}
                                <div className='item-main-details'>
                                    <span className="item-name">{item.item_name}</span>
                                    {item.price !== "0.00" && <span className="item-price">£{item.price}</span>}
                                </div>

                            </div>

                            <div className="action-buttons">
                                {/* <button className="view-btn">
                                    <i className="fas fa-eye"></i>
                                </button> */}
                                {/* <button className="edit-btn">
                                    <i className="fas fa-pencil-alt"></i>
                                </button> */}
                                <button className="soldout-btn" onClick={() => handleMarkAsSoldout(item)}>
                                    <i className="fas fa-ban"></i>
                                </button>
                                <button className="delete-btn" onClick={() => handleDeleteItem(item)}>
                                    <i className="fas fa-trash-alt"></i>
                                </button>
                            </div>
                        </div>
                    ))
                )}
            </div>

            {/* Pagination Controls */}
            <div className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index + 1}
                        onClick={() => handlePageChange(index + 1)}
                        className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>

            {/* Add Item Modal */}
            {
                showAddItemModal && (
                    <Modal
                        title="Add Menu Item"
                        onClose={handleCloseModal}
                        onSubmit={handleAddSubmit}
                        submitButtonText="Add Menu Item"
                        showCancel={false}
                        showSubmitButton={true}
                        className="modal-content"
                        requiredOptions={requiredOptions}
                        optionalOptions={optionalOptions}
                    >
                        <div className="profiles-form">
                            {/* Other form fields */}
                            <div className="form-group">
                                <label>Photo</label>
                                {/* Display either the uploaded photo or the placeholder */}
                                <img
                                    className='item-photo'
                                    src={photoSrc}
                                    alt="Menu Item"
                                    style={{ width: '250px', height: '150px', borderRadius: '10%', margin: 'auto' }}
                                />

                                {/* Hidden file input field */}
                                <input
                                    type="file"
                                    accept="image/jpeg, image/jpg, image/png, image/webp"
                                    onChange={handlePhotoChange}
                                    id="fileInput"
                                    style={{ display: 'none' }}
                                />

                                {/* Buttons for choosing or removing the photo */}
                                <div className='photo-buttons'>
                                    <label
                                        htmlFor="fileInput"
                                        className='photo-btn'
                                    >
                                        Choose file
                                    </label>
                                    {/* Remove photo button, only displayed if a photo is uploaded */}
                                    {photo && (
                                        <label className="photo-btn photo-btn2" onClick={handleRemovePhoto}>
                                            <i className="fas fa-times"></i>
                                        </label>
                                    )}
                                </div>

                                {/* Display the name of the selected file or a placeholder text */}
                                <div id="fileName">
                                    {photo ? photo.name : 'No file chosen'}
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Item Name</label>
                                <input
                                    type="text"
                                    value={itemName}
                                    onChange={(e) => setItemName(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label>Item Description</label>
                                <textarea
                                    value={itemDescription}
                                    rows={4}
                                    onChange={(e) => setItemDescription(e.target.value)}
                                />
                            </div>

                            <div className="form-group">
                                <label>Price (£)</label>
                                <input
                                    type="number"
                                    step="0.01"
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                />
                            </div>

                            <div className="form-group">
                                <label>Category</label>
                                <div className="select-wrapper">
                                    <select
                                        value={categoryId}
                                        onChange={(e) => setCategoryId(e.target.value)}
                                        required
                                    >
                                        <option value="">Select Category</option>
                                        {categories.map((category) => (
                                            <option key={category.id} value={category.id}>
                                                {category.category_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            {/* Required Options Section */}
                            <div className="form-group">
                                <label>Required Options (Radio Buttons)</label>
                                <div className="select-wrapper">
                                    <select onChange={(e) => handleRequiredOptionSelect(e.target.value)} defaultValue="">
                                        <option value="">Select Required Option</option>
                                        {requiredOptions.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.option_name} (£{option.option_price})
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {/* Display selected options */}
                                {selectedRequiredOptions.map((option, index) => (
                                    <div key={index} className="selected-option">
                                        <input
                                            type="text"
                                            value={`${option.option_name} - £${option.option_price}`}
                                            readOnly
                                        />
                                        <button onClick={() => handleRemoveRequiredOption(index)}>Remove</button>
                                    </div>
                                ))}



                                {/* Display new required option input fields */}
                                {newRequiredOptions.map((option, index) => (
                                    <div key={index} className="new-option-fields">
                                        <input
                                            type="text"
                                            value={option.option_name}
                                            onChange={(e) => handleNewOptionNameChange(e, index)}
                                            placeholder="Option Name"
                                        />
                                        <input
                                            type="number"
                                            step="0.01"
                                            value={option.option_price}
                                            onChange={(e) => handleNewOptionPriceChange(e, index)}
                                            placeholder="Price"
                                        />
                                        <button type="button" onClick={() => handleRemoveNewRequiredOption(index)}>Remove</button>
                                    </div>
                                ))}

                                {/* Add new required option button */}
                                <button type="button" onClick={handleAddNewRequiredOption}>Add Option</button>
                            </div>


                            {/* Optional Options Section */}
                            <div className="form-group">
                                <label>Optional Options (Checkboxes)</label>
                                <div className="select-wrapper">
                                    <select onChange={(e) => handleOptionalOptionSelect(e.target.value)} defaultValue="">
                                        <option value="">Select Optional Option</option>
                                        {optionalOptions.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.option_name} (+£{option.option_price})
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {/* Display selected options */}
                                {selectedOptionalOptions.map((option, index) => (
                                    <div key={index} className="selected-option">
                                        <input
                                            type="text"
                                            value={`${option.option_name} - £${option.option_price}`}
                                            readOnly
                                        />
                                        <button type="button" onClick={() => handleRemoveOptionalOption(index)}>Remove</button>
                                    </div>
                                ))}

                                {/* Display new optional option input fields */}
                                {newOptionalOptions.map((option, index) => (
                                    <div key={index} className="new-option-fields">
                                        <input
                                            type="text"
                                            value={option.option_name}
                                            onChange={(e) => handleNewOptionalOptionNameChange(e, index)}
                                            placeholder="Option Name"
                                        />
                                        <input
                                            type="number"
                                            step="0.01"
                                            value={option.option_price}
                                            onChange={(e) => handleNewOptionalOptionPriceChange(e, index)}
                                            placeholder="Price"
                                        />
                                        <button type="button" onClick={() => handleRemoveNewOptionalOption(index)}>Remove</button>
                                    </div>
                                ))}
                                {/* Add new optional option button */}
                                <button type="button" onClick={handleAddNewOptionalOption}>Add Option</button>

                            </div>
                            {error && (
                                <div className="error-overlay">
                                    <div className='error-overlay-container'>
                                        <i className="fas fa-exclamation-triangle"></i>
                                        <p className="error-overlay-message">{error}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Modal>
                )
            }

            {
                isSubmitting && (
                    <div className="spinner-overlay">
                        <div className="spinner"></div>
                    </div>
                )
            }




            {/* Delete Item Modal */}
            {
                showDeleteModal && (
                    <Modal
                        title="Delete Item"
                        onClose={handleCloseModal}
                        onSubmit={confirmDeleteItem}
                        submitButtonText="Delete Item"
                        showCancel={true}
                        showSubmitButton={true}
                        className="delete-modal-content"
                    >
                        <div className="delete-modal-heading">
                            <p>Are you sure you want to permanently delete this item from the database?</p>
                        </div>
                    </Modal>
                )
            }

            {/* Inactive Item Modal */}
            {
                showSoldoutModal && (
                    <Modal
                        title="Mark Item as Soldout"
                        onClose={handleCloseModal}
                        onSubmit={confirmMarkAsSoldout}
                        submitButtonText="Mark as Soldout"
                        showCancel={true}
                        showSubmitButton={true}
                        className="delete-modal-content"
                    >
                        <div className="delete-modal-heading">
                            <p>Are you sure you want to mark this item as sold out</p>
                        </div>
                    </Modal>

                )
            }
            {
                error && (
                    <div className="error-overlay">
                        <div className='error-overlay-container'>
                            <i className="fas fa-exclamation-triangle"></i>
                            <p className="error-overlay-message">{error}</p>
                        </div>
                    </div>
                )
            }

        </div >
    );
};

export default ItemsTab;
